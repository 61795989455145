<script>
export default {
  name: "PlanCard2",
  components: {},
  props: {
    plan: {
      type: Object,
      default() {
        return {
          benefits: [],
          description: null,
          disableToSubscribe: false,
          idPaypal: "",
          image: null,
          isRecommended: false,
          name: "",
          price: "",
        };
      },
    },
    escolas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      schoolId: this.escolas[0]?.id,
    };
  },
  computed: {
    auxText() {
      return this.plan.name.toLowerCase() === "profissional"
        ? "Todas vantagens do Plano Básico +"
        : this.plan.name.toLowerCase() === "premium" &&
            "Todas vantagens do Plano Profissional +";
    },
    badge() {
      return require("@/assets/badge.png");
    },
    benefits() {
      return this.plan.benefits.filter((benefit) => {
        return !benefit.includes("Benefícios");
      });
    },
    hasBadge() {
      return this.plan.name.toLowerCase() === "premium";
    },
    hasRadioButtons() {
      return this.plan.name.toLowerCase() === "avançado";
    },
    theme() {
      return this.plan.name.toLowerCase() === "avançado" ? "blue" : "white";
    },
    topText() {
      return this.plan.isRecommended
        ? "Mais escolhido"
        : this.plan.name.toLowerCase() === "premium" &&
            "Para quem busca se diferenciar no mercado";
    },
  },
  methods: {
    async selectPlan() {
      this.$emit("click", this.schoolId);
    },
  },
};
</script>

<template>
  <div
    class="c-plan-card2"
    :class="{
      'has-top-text': topText,
      'theme-blue': theme === 'blue',
    }"
  >
    <div v-if="topText" class="header-plan">{{ topText }}</div>

    <div class="title-card">{{ plan.name }}</div>
    <span v-if="plan.unavailable" class="unavailable-label"
      >(Temporariamente indisponível)</span
    >

    <div class="line" />

    <div class="value-card">
      Apenas <strong>{{ plan.price }}/mês</strong>
    </div>

    <div class="card-button">
      <v-btn
        :color="theme === 'blue' ? '#00BA51' : '#265ACC'"
        :disabled="
          plan.disableToSubscribe ||
          (escolas.length > 0 && !schoolId) ||
          plan.unavailable
        "
        x-large
        class="button"
        @click="selectPlan"
      >
        Assinar
      </v-btn>
    </div>

    <div v-if="auxText" class="aux-text">
      {{ auxText }}
    </div>

    <div class="c-items">
      <div
        v-for="(benefit, index) in benefits"
        :key="`${benefit} ${index}`"
        class="check-icon-container"
      >
        <div class="check-icon-background">
          <v-icon :color="theme === 'blue' ? '#19E06F' : '#265ACC'" medium>
            mdi-check-bold
          </v-icon>
        </div>
        <div class="text-beneficios">{{ benefit }}</div>
      </div>
    </div>

    <div v-if="hasRadioButtons && escolas.length > 0" class="c-radio-buttons">
      <div class="header">Escolha uma escola extra</div>
      <div class="content">
        <v-radio-group v-model="schoolId" class="radio-buttons">
          <v-radio
            v-for="escola in escolas"
            :key="escola.id"
            color="secondary"
            :label="escola.name"
            :value="escola.id"
          ></v-radio>
        </v-radio-group>
      </div>
    </div>

    <div v-if="hasBadge" class="c-badge-wrapper">
      <div class="c-badge">
        <v-img widht="100%" max-width="50px" :src="badge" />
        <span class="text-span">Acesso a todas as escolas</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.unavailable-label {
  color: #ff0000;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: block;
  margin-top: -16px;
  margin-bottom: 10px;
}
.c-plan-card2 {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 350px;
  border-radius: 20px;
  position: relative;
  padding: 0 20px;

  &.has-top-text {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &.theme-blue {
    background-color: #2659cb;

    .header-plan {
      background-color: #00ba51;
    }

    .button {
      box-shadow: 0px 3px 20px #17ff7c53;

      &:disabled {
        background-color: #b7b7b7 !important;
        box-shadow: none;
      }
    }

    .title-card {
      color: #ffffff;
    }

    .value-card {
      color: #ffffff;
    }

    .aux-text {
      color: #ffffff;
    }

    .text-beneficios {
      color: #ffffff;
    }
  }

  .line {
    border-bottom: 1px solid #e8e8e8;
  }

  .header-plan {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #265acc;
    color: #ffffff;
    top: -25px;
    left: 0;
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 8px 0;
    font-size: 14px;
    text-align: center;
  }

  .title-card {
    color: #265acc;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 15px;
  }

  .value-card {
    color: #265acc;
    text-align: center;
    padding-top: 15px;
  }

  .card-button {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .aux-text {
    margin-top: 30px;
    color: #265acc;
  }

  .button {
    border-radius: 10px;
    width: 100%;
    box-shadow: none;
    color: white;
    text-transform: initial;
    font-weight: 600;
  }

  .check-icon-container {
    padding-top: 20px;
    display: flex;
  }

  .check-icon-background {
    background-color: #efefef;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .text-beneficios {
    color: #265acc;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }

  .c-radio-buttons {
    margin: 30px 0 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 3px 20px #00000015;
    padding: 10px;

    .header {
      color: #265acc;
      font-weight: 600;
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 5px;
    }

    .content {
      max-height: 120px;
      overflow: auto;
      padding-top: 10px;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ebebeb;
        border: 0px none #ffffff;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
      }
      &::-webkit-scrollbar-thumb:active {
        background: #b7b4b4;
      }
      &::-webkit-scrollbar-track {
        background: #ffffff;
        border: 0px none #ffffff;
        border-radius: 47px;
      }
      &::-webkit-scrollbar-track:hover {
        background: #ffffff;
      }
      &::-webkit-scrollbar-track:active {
        background: #333333;
      }
      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    }

    .radio-buttons {
      margin: 0;
    }
  }

  .c-badge-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 20px;

    .c-badge {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 35px 10px;
      border-radius: 10px;
      background-color: #f0f4fd;
      text-align: center;
    }
    .text-span {
      color: #265acc;
      font-weight: 600;
      max-width: 200px;
    }
  }
}
</style>
